// Script is loaded with the "defer" attribute, and will execute after the HTML is fully parsed.

function getUserId() {
  let userId = localStorage.getItem("user_id");
  if (!userId) {
    userId = localStorage.getItem("client_id");
    if (userId) {
      localStorage.removeItem("client_id");
    } else {
      userId = Math.random().toString(36).substring(2) + "_" + Date.now().toString(36);
    }
    localStorage.setItem("user_id", userId);
  }
  return userId;
}

const userId = getUserId();

async function sendEvent(eventType) {
  const baseUrl = document.body.getAttribute("data-api-host") || window.location.origin;
  const eventDetails = {
    userId: userId,
    eventType: eventType,
    url: window.location.href,
    referrer: document.referrer,
    screen: `${window.screen.width}x${window.screen.height}`,
    language: navigator.languages ? navigator.languages.join(", ") : navigator.language,
  };

  try {
    await fetch(`${baseUrl}/api/v1/event`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(eventDetails),
    });
  } catch (err) {
    console.error("Tracking failed:", err);
  }
}

document.addEventListener("click", (event) => {
  const target = event.target.closest("a");
  if (target && (target.href.startsWith("tel:") || target.href.startsWith("mailto:"))) {
    sendEvent(target.href.startsWith("tel:") ? "phone_click" : "email_click");
  }
});

sendEvent("page_view");

const canonicalUrl = document.querySelector('link[rel="canonical"]');
if (canonicalUrl && canonicalUrl.href !== window.location.href) {
  window.history.replaceState(null, "", canonicalUrl.href);
}

localStorage.removeItem("pages");
localStorage.removeItem("tracking");
